import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MsalBroadcastService, MsalService, MSAL_INSTANCE, MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { DownloadServiceFactory } from '../core/download/download.factory';
import { MonitoringService } from '../core/monitoring/monitoring.service';
import { Subject, fromEvent } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { RolesPermissionService } from '../core/services/permissions/rolesPermission.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProductEventFactory } from '../core/product/productEvent.factory';
import { AuthenticationResult, EventMessage, EventType, IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { b2cConfig } from '../app.module';
import { AppState } from '../core/store';
import { Store } from '@ngrx/store';
import { SettingsService } from '../core/settings/settings.service'
import { LsAuthService } from '../core/services/auth-behavior/lsauth.service';
import { VettingService } from '../core/services/vatting/vetting.service';
import { Router } from '@angular/router';
import { OnboardingService } from '../core/services/onboarding/onboarding.service';


class ChangePasswordEvent extends Event {
}
@Component({
  selector: 'ls-root',
  template: `
  <div class="container-fluid">
    <router-outlet  (activate)="onActivate($event)"></router-outlet>
    
  </div>`,
  styleUrls: [
    './app.component.scss'
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  subscription: any;
  loggedIn: boolean;
  isIframe: boolean;
  changePasswordSubscription: any;
  vatpending: boolean = true;
  loading: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private downloadServiceFactory: DownloadServiceFactory,
    productEventFactory: ProductEventFactory,
    private monitoringService: MonitoringService,
    private rolesPermissionService: RolesPermissionService,
    private permissionsService: NgxPermissionsService,
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    @Inject(DOCUMENT) private document: Document,
    protected settingsService: SettingsService,
    private LsAuthService: LsAuthService,
    private store: Store<AppState>, private vattingService: VettingService,
    private onboardingService: OnboardingService,
    private router: Router) {
    downloadServiceFactory.init();
    productEventFactory.init();

  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.downloadServiceFactory.destroy();
  }



  setLoginDisplay() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }




  ngOnInit(): void {
    this.checkoutAccount();


    const changePasswordRequest: RedirectRequest = {
      authority: b2cConfig.authority.passwordReset,
      scopes: b2cConfig.scopes
    };

    const loginRequest: RedirectRequest = {
      authority: b2cConfig.authority.login,
      scopes: b2cConfig.scopes
    };

    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.msalSubject$.subscribe((event: EventMessage) => {
      if (event.eventType == EventType.LOGIN_FAILURE || event.eventType == EventType.ACQUIRE_TOKEN_FAILURE || event.eventType == EventType.SSO_SILENT_FAILURE) {
        if (event.error.message.indexOf('AADB2C90118') !== -1) {
          this.authService.loginRedirect(changePasswordRequest);
        }
        if (event.error.message.indexOf('AADB2C90091') !== -1) {
          this.authService.loginRedirect(loginRequest);
        }

        localStorage.setItem("ErrorDescription", event.error.message);
        this.router.navigate(['error']);
      }

    });

    this.changePasswordSubscription = fromEvent<ChangePasswordEvent>(this.document, 'changePassword').subscribe((e) => {
      this.authService.loginRedirect(changePasswordRequest);
    });


    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          localStorage.setItem("msal.idtoken", result.idToken);
          this.authService.instance.setActiveAccount(result.account);
          this.checkoutAccount();
        }
      },
      error: (error) => {
        console.log(error)
      }
    });

    if (this.loggedIn) {
      const expirydate = this.settingsService.getTokenExpiryDate();
      if (expirydate < new Date()) {
        this.logout();
        return;
      }

      const permissions = [];
      this.rolesPermissionService.getRolesAndPermissions().subscribe(x => {
        if (x) {
          x.forEach((role) => {
            role.permissions.forEach(element => {
              permissions.push(element.name.toUpperCase());
            });
          });
          this.permissionsService.addPermission(permissions);
        }
      });
    }

  }


  checkoutAccount() {

    let acc = this.msalInstance.getActiveAccount();

    if (acc) {
      if (acc.idTokenClaims["exp"] < new Date().getTime() / 1000) {
        this.logout();


      } else {
        this.setLoggedInAccount();
      }

    } else {
      if (localStorage.getItem("InitialLoadUrl") == null) {
        localStorage.setItem("InitialLoadUrl", window.location.href);
      }
    }
  }


  setLoggedInAccount() {
    this.msalInstance.setActiveAccount(this.msalInstance.getAllAccounts()[0]);
    let acc = this.msalInstance.getActiveAccount();

    if (acc) {
      localStorage.setItem("msal.idtoken", acc.idToken);
    }

    this.monitoringService.setAuthenticatedUserContext(this.msalInstance.getActiveAccount().idTokenClaims["sub"]);
    localStorage.setItem('signedinEmail', acc.idTokenClaims["email"].toString());
    if (localStorage.getItem("InitialLoadUrl")) {
      let redirurl = localStorage.getItem("InitialLoadUrl");
      localStorage.removeItem("InitialLoadUrl");
      window.location.href = redirurl;
    }
  }

  refresh() {
    this.vattingService.refreshVattingState.next(true);
  }

  logout() {

    this.msalInstance.initialize().then(() => { 
      this.LsAuthService.Logout();
    }).catch((error) => {
      console.error('Error initializing MSAL', error);
      let currentUrl = this.document.location.href.split('#')[0] + '#/?logout=true';
      this.document.location.href = currentUrl
    });

  }

  onActivate(event) {
    window.scroll(0, 0);
  }


}
