import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LsAuthService } from 'src/app/core/services/auth-behavior/lsauth.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  ErrorDescription: string;

  constructor(private router: Router,  private LsAuthService: LsAuthService) { 

    if(localStorage.getItem('ErrorDescription') != null)
      this.ErrorDescription = localStorage.getItem('ErrorDescription').split(":").join("<br>");
    else
      this.ErrorDescription = "An error occurred. Please try again later."; 
  }

  Logout(){
    this.LsAuthService.Logout();
  }

}
